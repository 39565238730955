import React from "react";
import { WalletScreen } from "../../../constants/types";
import { ModalComponent } from "../../common/ModalComponent";
import Amount from "./Amount";
import CreatePaymentMethod from "./CreatePaymentMethod";
import {
  useAppDispatch,
  getAcceptedPaymentMethod,
  getUserPayoutMethods,
  WalletSelector,
} from "@chidiahua/vermo-redux";
import { NotificationAlert } from "../../../utils/NotificationAlert";
import SelectPayment from "./SelectPayment";
import { CloseModal } from "../Style";
import { IoMdCloseCircle } from "react-icons/io";
import ConfirmScreen from "./ConfirmScreen";
import AddNewPayment from "./AddNewPayment";
import NGNPayout from "./NGNCreatePayout";
import SuccessfulPayment from "./SuccessfulPayment";
interface ModalInterface {
  onCancel?: any;
  visible: boolean;
  data?: any;
}
const AccountProcessor: React.FC<ModalInterface> = ({
  onCancel,
  visible,
  data,
}) => {
  const [loadingAmount, setLoading] = React.useState(false);

  const [currentScreen, setCurrentScreen] = React.useState<WalletScreen>(
    WalletScreen.AMOUNT
  );
  const dispatcher = useAppDispatch() as any;
  const backStep = (value: WalletScreen) => {
    setCurrentScreen(value);
  };
 
  const handleAmountAction = async (screen: WalletScreen) => {
    setLoading(true);

    await dispatcher(getUserPayoutMethods({}))
      .unwrap()
      .then((originalPromiseResponse: any) => {
        if (originalPromiseResponse?.getUserPayoutMethods?.length > 0) {
          setCurrentScreen(WalletScreen.SELECT_PAYMENT_METHOD);
          setLoading(false);
        } else {
          dispatcher(getAcceptedPaymentMethod({}))
            .unwrap()
            .then((originalPromiseResponse: any) => {
              if (
                originalPromiseResponse?.getAcceptedPaymentMethods.length > 0
              ) {
                setLoading(false);
                setCurrentScreen(WalletScreen.CONFIRM_PAYMENT);
              } else {
                setLoading(false);
                NotificationAlert(
                  "error",
                  "Error occured",
                  "Please try again."
                );
              }
            });
        }
      })
      .catch((err: any) => {
        setLoading(false);
        NotificationAlert("error", "Error occured", "Please try again.");
      });
  };
  const handleNextScreen = (value: WalletScreen) => {
    setCurrentScreen(value);
  };
  const confirmPayment = () => {
    console.log();
  };
  return (
    <>
      <ModalComponent visible={visible} onCancel={() => {}}>
        {currentScreen !== WalletScreen.AMOUNT &&
          currentScreen !== WalletScreen.SUCCESSFUL_PAYMENT && (
            <CloseModal onClick={() => onCancel()}>
              <IoMdCloseCircle />
            </CloseModal>
          )}
        <div
          style={{ padding: "1vw 1.5vw", width: "100%", textAlign: "center" }}
        >
          {currentScreen === WalletScreen.AMOUNT && (
            <Amount
              loading={loadingAmount}
              close={() => onCancel()}
              next={handleAmountAction}
            />
          )}
          {currentScreen === WalletScreen.CREATE_PAYMENT_METHOD && (
            <CreatePaymentMethod close={backStep} next={handleNextScreen} />
          )}
          {currentScreen === WalletScreen.SELECT_PAYMENT_METHOD && (
            <SelectPayment close={backStep} next={handleNextScreen} />
          )}
          {currentScreen === WalletScreen.CONFIRM_PAYMENT && (
            <ConfirmScreen next={handleNextScreen} close={backStep} />
          )}
          {currentScreen === WalletScreen.ADD_NEW_PAYMENT && <AddNewPayment />}
          {currentScreen === WalletScreen.NGNCREATEPAYOUT && (
            <NGNPayout close={backStep} next={handleNextScreen} />
          )}
          {currentScreen === WalletScreen.SUCCESSFUL_PAYMENT && (
            <SuccessfulPayment next={onCancel} />
          )}
        </div>
      </ModalComponent>
    </>
  );
};

export default AccountProcessor;
