import React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes";
import { device } from "../../theme/breakPoints";
import { IRoutes } from "../..";
import heptagon from "../../asset/heptagon.png";
import plcover3 from "../../asset/plcover3.png";
import bg from "../../asset/bg.jpg";

import triangle from "../../asset/triangle.png";
import semicircle from "../../asset/semiCircle.png";
import {
  mode,
  useAppDispatch,
  useAppSelector,
  toggleDark,
  toggleLight,
} from "@chidiahua/vermo-redux";
interface AuthProp {
  newImage?: boolean;
  children?: any;
}
const Auth = ({ children, newImage }: AuthProp) => {
  const lightmode = useAppSelector(mode);
  const dispatcher = useAppDispatch();
  const onChange = (): void => {
    lightmode === "dark" ? dispatcher(toggleLight()) : dispatcher(toggleDark());
  };

  const allowOpacity = lightmode !== "dark" ? true : false;
  return (
    <Container
    // allowOpacity={allowOpacity}
    >
      <div
        onClick={onChange}
        style={{
          zIndex: 100,
          position: "absolute",
          top: "20px",
          right: "20px",
          cursor: "pointer",
        }}
      >
        {lightmode === "light" ? "🌞" : "🌜"}
      </div>
      <div
        style={{ position: "absolute", top: 0, width: "100vw", height: "100%" }}
      >
        <img
          alt="background"
          src={newImage ? bg : plcover3}
          style={{ filter: "grayscale(70%)", width: "100%", height: "100%" }}
        />
      </div>
      <Gradient />

      {children}
    </Container>
  );
};
export default Auth;
interface IContainer {
  allowOpacity?: boolean;
}
export const Gradient = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  opacity: 1;

  height: 100%;
  background: linear-gradient(
    120deg,
    ${(props) => props.theme.authBackground + "cc"},
    ${(props) => props.theme.authBackground + "dd"},
    ${(props) => props.theme.authBackground + "ee"},
    ${(props) => props.theme.authBackground + "ff"},
    ${(props) => props.theme.authBackground}
  );
`;
const Container = styled.div<IContainer>`
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 100vh;
  :before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    /* z-index: 0; */
    width: 100%;
    height: 100%;
    /* opacity: 0.2; */
    /* opacity: ${(props) => (props.allowOpacity ? 0.1 : 1)};
    background-image: url(${heptagon}), url(${semicircle}), url(${triangle}); */
    background-size: 550px 450px, 350px 350px, 100px 100px;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: bottom left, top right, top left;
  }
  @media ${device.tablet} {
    background-color: ${(props) => props.theme.authBackground};
    padding: 0;
    /* opacity: 0.2; */
  }
`;
