//@ts-nocheck
import { LeagueDetailsSelector } from "@chidiahua/vermo-redux";
import { Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsFilter } from "react-icons/bs";
import { date } from "yup/lib/locale";
import { ButtonComponent } from "../../components/common/Button";
import DateInput from "../../components/common/DateInput";
import DropDown from "../../components/common/DropDown";
import Loading from "../../components/common/Loading";
import {
  EndContainer,
  FilterContainer,
  MenuItem,
  MidContainer,
  PageTitle,
  SportTitle,
  StyledDropdown,
  StyledFilter,
  StyledIcon,
  StyledMenu,
  StyledTabs,
  StyledTapPane,
} from "../../components/common/StyleComponents";
import { StyledDrawer } from "../../components/layouts/Drawer";
import AllGames from "../../components/Tabs/AllGames";
import FinishedGames from "../../components/Tabs/FinishedGames";
import LiveGames from "../../components/Tabs/LiveGames";
import Scheduled from "../../components/Tabs/Scheduled";

const Games = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedLeague, setSelectedLeague] = useState("");
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [showDrawer, setShowDrawer] = useState(false);
  const [sport, setSport] = React.useState("Football");
  const allSports = ["FootBall", "Basketball", "TableTennis", "Boxing", "UFC"];
  const divisions = ["La liga", "Bundesliga", "French Open"];
  const country = ["Angola", "Spain", "Sample", "Sample"];
  const menu = (
    <StyledMenu>
      {allSports?.map((sport: string) => {
        return <MenuItem onClick={() => setSport(sport)}>{sport}</MenuItem>;
      })}
    </StyledMenu>
  );
  const leaguesDetails = LeagueDetailsSelector() as any;

  const leagueFilterData = () => {
    let formattedData = {} as any;
    //@ts-ignore
    if (leaguesDetails?.getLeagues) {
      for (const data of leaguesDetails?.getLeagues) {
        console.log(" ----data from games--------:", data)
        if (formattedData[data?.country]) {
          formattedData[data?.country] = {
            ...formattedData[data?.country],
            [data?.leagueName]: data?.apiFootballLeagueId,
          };
        } else {
          formattedData[data?.country] = {
            [data?.leagueName]: data?.apiFootballLeagueId,
          };
        }
      }
      return formattedData;
    }
    return [];
  };
  
  const formattedLeagues = leagueFilterData();

  const countriesList = Object.keys(formattedLeagues)
    ?.map((dt) => dt)
    .sort();

  const divisionList =
    selectedCountry && formattedLeagues[selectedCountry]
      ? Object.keys(formattedLeagues[selectedCountry])?.map((dt) => dt)
      : [];

  useEffect(() => {
    if (selectedCountry && divisionList) {
      setSelectedLeague(divisionList[0]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedLeague(divisionList);
  }, []);
  
  const formatDate = (value?: any) => {
    const newDate = value ? new Date(value) : new Date();
    return moment(newDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  };
  return (
    <>
      <PageTitle>
        <h1>Sports</h1>
        <h2>Create challenge from the list of available games</h2>
      </PageTitle>
      <br />
      <Row justify="space-between">
        <MidContainer md={{ span: 16 }} sm={{ span: 24 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
              marginBottom: "15px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <SportTitle>{sport}</SportTitle>
              <StyledDropdown overlay={menu}>
                <StyledIcon />
              </StyledDropdown>
            </div>
            <div>
              <StyledFilter onClick={() => setShowDrawer(true)}>
                <BsFilter />
              </StyledFilter>
            </div>
          </div>
          <StyledTabs defaultActiveKey="1">
            <StyledTapPane tab="All Games" key="1">
              <AllGames
                fixtureDate={
                  !selectedDate ? formatDate() : formatDate(selectedDate)
                }
                fixtureLeague={
                  formattedLeagues?.[selectedCountry]?.[selectedLeague]
                }
              />
            </StyledTapPane>
            {/* <StyledTapPane tab="LIVE Games" key="2">
              <LiveGames />
            </StyledTapPane>
            <StyledTapPane tab="Finished" key="3">
              <FinishedGames />
            </StyledTapPane>
            <StyledTapPane tab="Scheduled" key="4">
              <Scheduled />
            </StyledTapPane> */}
          </StyledTabs>
        </MidContainer>
        <EndContainer md={{ span: 7 }}>
          <FilterContainer>
            {countriesList?.length ? (
              <>
                <p className="filter"> Filter games</p>
                <br />
                <DropDown
                  placeholder="Select country"
                  values={countriesList}
                  label="Country"
                  defaultValue={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e);
                  }}
                />
                <DropDown
                  values={divisionList}
                  defaultValue={selectedLeague}
                  placeholder="Select division"
                  // values={divisions}
                  onChange={(e) => {
                    console.log(e);
                    setSelectedLeague(e);
                  }}
                  label="Division"
                />

                <DateInput
                  label="Date"
                  // defaultValue={moment(new Date().toISOString()).format()}
                  style={{ marginBottom: 15 }}
                  onChange={(e) => setSelectedDate(e)}
                />
              </>
            ) : (
              <>
                <Loading skeleton={true} />
              </>
            )}
          </FilterContainer>
        </EndContainer>
        <StyledDrawer
          bodyStyle={{ padding: "10px" }}
          closable={false}
          onClose={() => setShowDrawer(false)}
          open={showDrawer}
          placement="bottom"
          key="bottom"
          width={"100%"}
        >
          <FilterContainer>
            {countriesList?.length ? (
              <>
                <p className="filter"> Filter games</p>
                <br />
                <DropDown
                  placeholder="Select country"
                  values={countriesList}
                  label="Country"
                  defaultValue={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e);
                  }}
                />
                <DropDown
                  values={divisionList}
                  defaultValue={selectedLeague}
                  placeholder="Select division"
                  onChange={(e) => {
                    console.log(e);
                    setSelectedLeague(e);
                  }}
                  label="Division"
                />

                <DateInput
                  label="Date"
                  style={{ marginBottom: 15 }}
                  onChange={(e) => setSelectedDate(e)}
                />
              </>
            ) : (
              <>
                <Loading skeleton={true} />
              </>
            )}
          </FilterContainer>
        </StyledDrawer>
      </Row>
    </>
  );
};

export default Games;
