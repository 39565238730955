import React, { useEffect, useState } from "react";
import { formatNumberAmount } from "../../utils/helperfuntions";
import { ConfirmBet, ConfirmBetContent } from "../../Views/GameDetails/Style";
import { ButtonComponent } from "../common/Button";
import { ModalComponent } from "../common/ModalComponent";
import { Challenger, Selection, Team } from "../common/StyleComponents";
import { StyledTable } from "../common/TableComponent";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ChallengesSelector, WalletSelector } from "@chidiahua/vermo-redux";
import { acceptChallengeAction, getFriendsChallengeAction } from "@chidiahua/vermo-redux/lib/actions/challengeAction";
import moment from "moment";
import { NotificationAlert } from "../../utils/NotificationAlert";
import { Vermo } from "../common/styled";
import { Dropdown, Option } from "../common/DropDown";

const FriendsChallenge = () => {
  const { myFriendsChallenges,  userAcceptChallengesLoading } = ChallengesSelector() as any;
  const dispatcher: ThunkDispatch<any, any, any> = useDispatch();
  const [walletValue, setWalletValue] = useState<string | null>(null);
  const { userWallets } = WalletSelector() as any;
  const [challengeDetails, setChallengeDetails] = React.useState() as any;
  const [openModal, setModal] = React.useState(false);
  const handleCancelModal = () => {
    setChallengeDetails("");
    setModal(false);
  };


  useEffect(() => {
    dispatcher(getFriendsChallengeAction({}));
  },[dispatcher]);

  const friendsData  = myFriendsChallenges?.getFriendsChallenges;
  const wallet = userWallets?.getUserWallets || [];


  const handleAcceptChallenge = async () => {
    if (!walletValue || !challengeDetails?.id) return;

    const payload = {
      gameBetId: challengeDetails?.id,
      walletId: walletValue,
    };

    try {
      const res = await dispatcher(acceptChallengeAction(payload)).unwrap();
      const alertType = res.acceptChallenge.status ? "success" : "error";
      NotificationAlert(alertType, res.acceptChallenge.msg);
      window.location.reload();
    } catch (error: any) {
      NotificationAlert("error", error.message);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, others: any) => (
        <div>
          <div style={{ display: "flex" }}>
            {moment(text).format("DD.MM.YYYY")}
          </div>
          <div>{moment(text).format("hh:mm a")}</div>
        </div>
      ),
    },

    {
      title: "Game Type",
      dataIndex: "gameType",
      key: "gameType",
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (_: any, record: any) => (
        <Team>
          <div className="content">
            {/* <img className="image" src={props?.homeLogo} alt={props.homeName} /> */}
            <p className="text" style={{ textAlign: "center" }}>
              {record.createdByTeam}
            </p>
          </div>
          <p className="text">VS</p>
          <div className="content">
            {/* <img className="image" src={props?.awayLogo} alt={props.awayName} /> */}
            <p className="text">{record.acceptedByTeam}</p>
          </div>
        </Team>
      ),
    },

    {
      title: "Selections",
      dataIndex: "selection",
      key: "selection",
      render: (text: string, record: any) => {
        return (
          <div>
            <Selection
              style={{
                border: `1px solid ${
                  record?.createdBy?.id ? "green" : "red"
                }`,
              }}
            >
              {record?.createdByTeam}
            </Selection>
            <Selection
              style={{
                border: `1px solid ${
                  record?.acceptedBy?.id ? "red" : "green"
                }`,
              }}
            >
              {record?.acceptedByTeam}
            </Selection>
          </div>
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div>
          <Team>${formatNumberAmount(+text * 1)}</Team>
        </div>
      ),
    },

    {
      title: "Bet Type",
      dataIndex: "betType",
      key: "betType",
    },
    {
      title: "Challenger",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_: string, record: any) => (
        <div>
          <Challenger>{record?.createdBy?.name}</Challenger>
        </div>
      ),
    },
  ];


 
  return (
    <div>
      <StyledTable
        scroll={{ x: 600 }}
        columns={columns}
        dataSource={friendsData}
        onRow={(record: any) => {
          return {
            onClick: (e) => {
              setModal(true);
              setChallengeDetails(record);
            },
          };
        }}
      />
      <ModalComponent onCancel={handleCancelModal} visible={openModal}>
        <div style={{ padding: "10px" }}>
          <ConfirmBet>Bet Challenge Details</ConfirmBet>
          <br />
          <ConfirmBetContent>
            <p className="details">
              You are about to accept a bet challenge with the following
              details:
            </p>
            <br />
            <p className="detailsInfo">
              Bet Type: <span>{challengeDetails?.betType}</span>
            </p>
            <p className="detailsInfo">
              Winning: <span> ${formatNumberAmount(+challengeDetails?.amount * 2)} {}</span>
            </p>

            <p className="detailsInfo">
              Club selection:{" "}
              <span>
                {challengeDetails?.acceptedByTeam}
              </span>
            </p>
          </ConfirmBetContent>

          <div style={{ margin: "20px 0px", }}>
              <Vermo.FlexCenter>
                <Vermo.TextSmall textSmall>
                  Select Withdraw Wallet
                </Vermo.TextSmall>
                <Vermo.SelectBorder>
                  <Dropdown onChange={(e) => setWalletValue(e.target.value)}>
                    <Option disabled selected value="">
                      Select a wallet
                    </Option>
                    {wallet?.map((wallet: any) => (
                      <Option value={wallet?.id}>
                        {wallet?.currencyType?.kind}
                      </Option>
                    ))}
                  </Dropdown>
                </Vermo.SelectBorder>
              </Vermo.FlexCenter>
            </div>
          <div style={{ width: "fit-content", margin: "0 auto" }}>
            <ButtonComponent onClick={() => handleAcceptChallenge()}
                disabled={!walletValue || challengeDetails?.amount === undefined ? true : false} text="Accept Challenge" loading={userAcceptChallengesLoading} />
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default FriendsChallenge;
