import {
  getAcceptedPaymentMethod,
  getUserPayoutMethods,
  initPayOut,
  setUserPaymentDetails,
  useAppDispatch,
  WalletSelector,
} from "@chidiahua/vermo-redux";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { WalletScreen } from "../../../constants/types";
import { ButtonComponent } from "../../common/Button";
import {
  AddMoreText,
  DetailsText,
  ModalText,
  PaymentContent,
  Selected,
} from "../Style";
import { NotificationAlert } from "../../../utils/NotificationAlert";

interface ISelectPayment {
  close: (val: WalletScreen) => void;
  next: (val: WalletScreen) => void;
}
const SelectPayment: React.FC<ISelectPayment> = ({ close, next }) => {
  const dispatcher = useAppDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    getUserPayoutMethod,
    getUserPayoutMethodLoading,
    userPaymentDetails,
  } = WalletSelector() as any;
  const handleContinue = () => {
    // console.log(Math.floor(userPaymentDetails?.amount));
    setLoading(true);

    dispatcher(
      initPayOut({
        amount: Math.floor(userPaymentDetails?.amount),
        userPayoutMethodId: userPaymentDetails?.userPayment?.id,
      })
    )
      .unwrap()
      .then((originalPromiseResponse) => {
        setLoading(false);
        if (originalPromiseResponse?.initPayOut?.status) {
          NotificationAlert(
            "success",
            "OTP SENT",
            "Check mail for Verification Token"
          );

          next(WalletScreen.CONFIRM_PAYMENT);
        } else {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.initPayOut?.msg
          );
        }
        console.log("response", originalPromiseResponse);
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error occured", "Please try again.");
      });
    // next(WalletScreen.CONFIRM_PAYMENT);
  };
  React.useEffect(() => {
    dispatcher(getUserPayoutMethods({}));
  }, []);
  const [selectedPayment, setSelectedPayment] = React.useState();
  console.log("userPaymentDetails", userPaymentDetails);
  const allowedPayments = getUserPayoutMethod?.getUserPayoutMethods?.map(
    (data: any, index: any) => {
      console.log("data", JSON.parse(data?.paymentMethodDetails));
      console.log("new ", data);
      const val = JSON.parse(data?.paymentMethodDetails);
      return (
        <>
          <PaymentContent
            onClick={() => {
              setSelectedPayment(index + 1);
              dispatcher(
                setUserPaymentDetails({
                  ...userPaymentDetails,
                  userPayment: { ...data },
                })
              );
            }}
            key={index}
          >
            <div>
              <DetailsText>Bank Name: {val?.bankName}</DetailsText>
              <DetailsText>Account Number: {val?.accountNumber}</DetailsText>
              <DetailsText style={{ textTransform: "capitalize" }}>
                Payment Type: {val?.paymentType.split("_").join(" ")}
              </DetailsText>
            </div>
            {selectedPayment === index + 1 && (
              <Selected>
                <FaCheck />
              </Selected>
            )}
          </PaymentContent>
        </>
      );
    }
  );
  return (
    <div>
      {getUserPayoutMethodLoading && (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      )}
      <ModalText>Select Payment Method</ModalText>
      {allowedPayments}
      <AddMoreText onClick={() => close(WalletScreen.ADD_NEW_PAYMENT)}>
        Add Payment Methods
      </AddMoreText>
      <br />
      <Row style={{ width: "100%" }} justify="space-between">
        <Col sm={{ span: 11 }} md={{ span: 11 }}>
          <ButtonComponent
            colorprop={true}
            onClick={() => close(WalletScreen.AMOUNT)}
            text={"Previous"}
            style={{ marginTop: "10px" }}
          />
        </Col>
        <Col sm={{ span: 11 }} md={{ span: 11 }}>
          <ButtonComponent
            onClick={handleContinue}
            loading={loading}
            text={"Confirm"}
            disabled={!selectedPayment && true}
            style={{ marginTop: "10px" }}
          />
        </Col>
      </Row>
      <br />
    </div>
  );
};

export default SelectPayment;
