
import { useEffect } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { GeneralDataSelector, WalletSelector, getAllUsersAction, getUsersWalletAction } from "@chidiahua/vermo-redux";
import { Skeleton } from "antd";
import { Vermo } from "../../components/common/styled";
import { FaCopy as CopyIcon } from "react-icons/fa";
import { handleCopyClick } from "../../utils/helperfuntions";

const Profile = () => {
  const dispatch:ThunkDispatch<any, any, any> = useDispatch();
  const { userDetails, loading } = GeneralDataSelector() as any
  const { userWallets, userWalletLoading } = WalletSelector() as any

  // extracting user wallet information from wallet reducer
  const wallet = userWallets?.getUserWallets || []


  useEffect(() => {
    dispatch(getAllUsersAction({}))
    dispatch(getUsersWalletAction({}))
  },[dispatch])



  return (
    <Vermo.ProfileContainer>
      <Vermo.InnerContainer withMargin>
        <Vermo.FlexContainer>
          <Vermo.Flex>
            {loading ? <Skeleton.Avatar shape="circle" size="large" /> : <Vermo.Img src={userDetails?.me?.img} alt="" /> }
            
            <Vermo.TextWrapper>
              <Vermo.TextTitle withSize>{loading ? <Skeleton.Input active={true} /> : userDetails?.me?.name}</Vermo.TextTitle>
              <Vermo.TextSmall>{loading ? <Skeleton.Input active={true} /> : userDetails?.me?.username}</Vermo.TextSmall>
            </Vermo.TextWrapper>
          </Vermo.Flex>
          <CopyIcon className="copyIcon" size={30} color="gray" onClick={() => handleCopyClick(userDetails?.me?.username)} />
        </Vermo.FlexContainer>
      </Vermo.InnerContainer>

      <Vermo.InnerContainer>
        <Vermo.FlexContainer>
          <Vermo.TextTitle withSize>Personal Information</Vermo.TextTitle>
        </Vermo.FlexContainer>

        <Vermo.FlexContainer>
            <Vermo.TextWrapper>
              <Vermo.TextSmall withPadding>Phone Number</Vermo.TextSmall>
              <Vermo.TextTitle>{loading ? <Skeleton.Input active={true} size="large" /> : userDetails?.me?.mobile}</Vermo.TextTitle>
            </Vermo.TextWrapper>

            <Vermo.FlexEnd>
              <Vermo.TextSmall withPadding>Country</Vermo.TextSmall>
              <Vermo.TextTitle>{loading ? <Skeleton.Input active={true} size="large" /> : userDetails?.me?.country}</Vermo.TextTitle>
            </Vermo.FlexEnd>
        </Vermo.FlexContainer>

        <Vermo.FlexContainer>
            <Vermo.TextWrapper>
              <Vermo.TextSmall withPadding>Gender</Vermo.TextSmall>
              <Vermo.TextTitle>{loading ? <Skeleton.Input active={true} size="large" /> : userDetails?.me?.gender === "M" ? "Male" : "Female"}</Vermo.TextTitle>
            </Vermo.TextWrapper>

            <Vermo.FlexEnd>
                <Vermo.TextSmall withPadding>Date of Birth</Vermo.TextSmall>
                <Vermo.TextTitle>{loading ? <Skeleton.Input active={true} size="large" /> : userDetails?.me?.dob}</Vermo.TextTitle>
            </Vermo.FlexEnd>
        </Vermo.FlexContainer>


{/* wallet information */}
       {wallet?.map((item: any) => (
          <div key={item?.id}>
             <Vermo.FlexContainer>
            <Vermo.TextWrapper>
              <Vermo.TextSmall withPadding>Wallet Balance</Vermo.TextSmall>
              <Vermo.TextTitle>{userWalletLoading ? <Skeleton.Input active={true} size="large" /> : item?.walletBalance}</Vermo.TextTitle>
            </Vermo.TextWrapper>

            <Vermo.FlexEnd>
                <Vermo.TextSmall withPadding>Wallet Type</Vermo.TextSmall>
                <Vermo.TextTitle>{userWalletLoading ? <Skeleton.Input active={true} size="large" /> : item?.currencyType?.kind}</Vermo.TextTitle>
            </Vermo.FlexEnd>
        </Vermo.FlexContainer>
          </div>
       ))}

<Vermo.FlexContainer>
            <Vermo.TextWrapper>
              <Vermo.TextSmall withPadding>Email Address</Vermo.TextSmall>
              <Vermo.TextTitle>{loading ? <Skeleton.Input active={true} size="large" /> : userDetails?.me?.email}</Vermo.TextTitle>
            </Vermo.TextWrapper>
        </Vermo.FlexContainer>

      </Vermo.InnerContainer>
    </Vermo.ProfileContainer>
  );
};

export default Profile;

