import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import { ButtonComponent } from "../common/Button";
import {
  AcceptedPaymentMethodSelector,
  GeneralDataSelector,
  fundWalletAction,
  getAcceptedPaymentMethod,
  getUsersWalletAction,
} from "@chidiahua/vermo-redux";
import { NotificationAlert } from "../../utils/NotificationAlert";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Vermo } from "../common/styled";
import { handleCopyClick } from "../../utils/helperfuntions";
import { FaCopy as CopyIcon } from "react-icons/fa";


interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
}
interface Values {
  values: string;
  kind: string;
}
const FundWalletModal = ({
  onCancel,
  visible,
}: ModalInterface) => {
  const dispatch:ThunkDispatch<any, any, any> = useDispatch();

  const [loadingState, setLoadingState] = useState(false);
  const { getAcceptedPaymentMethods} =  AcceptedPaymentMethodSelector() as any
  const { userDetails, loading } = GeneralDataSelector() as any;
  const userDetail = userDetails?.me;

  useEffect(() => {
    dispatch(getAcceptedPaymentMethod({}))
  },[dispatch])


  const fundMethod = [
    {
      id: 1,
      name: "CRYPTO"
    },

    {
      id: 2,
      name: "FIAT"
    }
  ]


  const fundWallet = (values: { payinMethod: string; amount: number; acceptedPaymentMethodId: string }) => {
  
    const payload = {
      payinMethod: values.payinMethod,
      amount: Number(values.amount),
      acceptedPaymentMethodId: values.acceptedPaymentMethodId
    };
    setLoadingState(true);
    dispatch(fundWalletAction(payload))
      .unwrap()
      .then((originalPromiseResponse: any) => {
        setLoadingState(false);
        if (!originalPromiseResponse?.fundWallet?.status) {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.fundWallet?.msg
          );
        } else {
          NotificationAlert(
            "success",
            "Successful",
            originalPromiseResponse?.fundWallet?.msg
          );
          onCancel();
            const paymentUrl = originalPromiseResponse?.fundWallet?.paymentUrl;
        if (paymentUrl) {
          window.open(paymentUrl, '_blank');
        }
          dispatch(getUsersWalletAction({}));
        }
      })
      .catch((err: any) => {
        setLoadingState(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };

  const formik = useFormik({
      initialValues: {
        payinMethod: "",
        amount: 0,
        acceptedPaymentMethodId: ""
      },
      onSubmit: fundWallet
  })
// userDetail contains array of coinAddress 
// For each of the coinAddress  show the Name and Address  
  return (
    <>
      <ModalComponent visible={visible} onCancel={onCancel}>
        <div style={{ padding: "1vw", width: "100%" }}>
          <Vermo.FlexCenter>
            <Vermo.TextTitle >Fund wallet your Wallet</Vermo.TextTitle>
          <Vermo.TextSmall textSmall>To fund your wallet, transfer the amount to any of the following address(es):</Vermo.TextSmall>
          </Vermo.FlexCenter>
          <br />

          {/* Displaying the coinAddress array with Name and Address */}
        {userDetail?.coinAddress?.length > 0 ? (
          userDetail.coinAddress.map((coin: any, index: number) => (
            <><div key={index} style={{ marginBottom: "1vw" }}>
              <Vermo.FlexCenter>
              <Vermo.Flex>
                <Vermo.TextTitle> {coin.name}</Vermo.TextTitle>
                <CopyIcon className="copyIcon" size={20} color="gray" onClick={() => handleCopyClick(coin.coinAddress)} />
                </Vermo.Flex>
  
                  <Vermo.TextSmall onClick={() => handleCopyClick(coin.coinAddress)} style={{ cursor: "pointer" }}>{coin.coinAddress}</Vermo.TextSmall>
                  
              </Vermo.FlexCenter>
            </div><br /></>
            
          ))
          
        ) : (
          <Vermo.TextSmall>No coin addresses found.</Vermo.TextSmall>
        )}
          

        </div>
        <br />
      </ModalComponent>
    </>
  );
};

export default FundWalletModal;
