import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import  { jwtDecode } from "jwt-decode"; 
import { ThunkDispatch } from "@reduxjs/toolkit";
import {  useApolloClient } from "@chidiahua/vermo-redux";



interface UserAccess {
    redirect?: string;
  }

export const Authorized: React.FC<UserAccess> = ({ redirect = "/login" }) => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();

  const client = useApolloClient();

  const handleLogOut = () => {
    dispatch(logOutAction());
    localStorage.clear();
    client.clearStore();
  };

  const accessToken = localStorage.getItem("token")


  const currentDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (accessToken) {
      const decod_access = jwtDecode(accessToken);
      if (decod_access.exp !== undefined && decod_access.exp * 1000 < currentDate.getTime()) {
        handleLogOut();
      }
    }
  }, [accessToken, dispatch, currentDate]);

  if (!accessToken) {
    return <Navigate to={redirect} replace />;
  }

  return <Outlet />;
};



const logOutAction = () => {
    return {
      type: "LOG_OUT",
    };
  };