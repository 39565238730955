import { useEffect } from "react";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import vermoLogo from "../../asset/vermoLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { desktop } from "../../theme/fontSize";
import { FiUserCheck, FiUserPlus } from "react-icons/fi";
import { FaWallet, FaUserCircle, FaUsers } from "react-icons/fa";
import { MdSpaceDashboard, MdSportsKabaddi } from "react-icons/md";
import { IoLogoGameControllerB } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import { GeneralDataSelector, getUserAction, useApolloClient } from "@chidiahua/vermo-redux";
import { Avatar, Skeleton } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux"

const SideMenu = () => {
  const dispatch:ThunkDispatch<any, any, any> = useDispatch()
  const { userDetails, loading } = GeneralDataSelector() as any;
  const userDetail = userDetails?.me;
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
 
 
  useEffect(() => {
    dispatch(getUserAction({}))
  },[dispatch])

  const handleLogOut = () => {
    localStorage.clear();
    client.clearStore();
    navigate("/login", { replace: true });
  };


  
  const routes = [
    {
      id: 1,
      title: "Home",
      icon: <MdSpaceDashboard />,
      route: "/dashboard",
    },
    {
      id: 2,
      title: "Challenges",
      icon: <MdSportsKabaddi />,
      route: "/challenges",
    },
    {
      id: 3,
      title: "Sports",
      icon: <IoLogoGameControllerB />,
      route: "/sports",
    },
    {
      id: 5,
      title: "Wallet",
      icon: <FaWallet />,
      route: "/wallet",
    },
    {
      id: 6,
      title: "Friends",
      icon: <FaUsers />,
      route: "/users",
    },

    {
      id: 4,
      title: "Profile",
      icon: <FaUserCircle />,
      route: "/profile",
    },
  ];
  return (
    <Container>
      <LogoContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={vermoLogo} alt="logo" />
          <h1>Vermo</h1>
        </div>
      </LogoContainer>
      <br />
      <Dpcontainer>
        <Avatar
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          src={userDetail?.img}
          style={{ flexShrink: 0, marginRight: "15px" }}
          icon={<UserOutlined />}
        />
        <p>{loading ? <Skeleton.Input active={true} size="small" /> : userDetail?.name}</p>
        <h5 className="points">0 points </h5>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FollowerTag>
            <span>
              <FiUserCheck />
            </span>
            <div>
              <p>{userDetail?.follower ?? "0"}</p>
              <p>Followers</p>
            </div>
          </FollowerTag>
          <FollowerTag>
            <span>
              <FiUserPlus />
            </span>
            <div>
              <p>{userDetail?.following ?? "0"}</p>
              <p>Following</p>
            </div>
          </FollowerTag>
        </div>
        <br />
        <div>
          {routes.map((val, id) => (
            <Nav
              key={id}
              onClick={() => navigate(val.route)}
              active={location.pathname === val.route ? true : false}
            >
              <Icon active={location.pathname === val.route ? true : false}>
                {val.icon}
              </Icon>
              <p>{val.title}</p>
            </Nav>
          ))}
          <LogoutContainer onClick={() => handleLogOut()}>
            <div>
              <IoLogOut />
            </div>
            <h1>Logout</h1>
          </LogoutContainer>
        </div>
       
      </Dpcontainer>
    </Container>
  );
};

export default SideMenu;
interface ActiveNav {
  active?: any;
}
const Container = styled.div`
  display: none;
  @media ${device.laptop} {
    width: 19vw;
    display: block;
    padding: 1.5vh 0 0 0;
    position: sticky;
    top: 0;
    z-index: 7;
    height: 100vh;
    left: 0;
    background: ${(props) => props.theme.navigationBackground};
    box-shadow: ${(props) => props.theme.subtleShadow};
  }
`;
export const Nav = styled.div<ActiveNav>`
  align-items: center;
  color: ${(props) =>
    props.active ? props.theme.textColor : props.theme.mutedColorSecondary};
  display: flex;
  margin: 2px 0;
  padding: 12px 0;
  padding-left: 3vw;
  border-left: ${(props) =>
    props.active ? `4px solid ${props.theme.primaryColor}` : `none`};
  background: ${(props) =>
    props.active
      ? "linear-gradient(90deg, hsla(253, 79%, 57%, 0.4) 0%, hsla(0, 0%, 100%, 0) 4%);"
      : "none"};
  p {
    margin: 0 0 0 10px;
    color: ${(props) =>
      props.active ? props.theme.textColor : props.theme.mutedColorSecondary};
    font-size: 16px;
  }
  @media ${device.laptop} {
    padding: 1vw 0;
    padding-left: 3vw;
    margin: 10px 0;

    margin: 0;
    font-size: ${desktop.normal};
    font-weight: 500;
    cursor: pointer;
    p {
      margin: 0 0 0 2vw;
      font-size: ${desktop.small};
    }
  }
`;

export const Icon = styled.div<ActiveNav>`
  margin: 0;
  transition: none !important;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${(props) =>
    props.active ? props.theme.primaryColor : props.theme.mutedColorSecondary};
  margin-left: ${(props) => (props.active ? "-4px" : 0)};
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    font-size: ${desktop.normal};
    color: ${(props) =>
      props.active
        ? props.theme.primaryColor
        : props.theme.mutedColorSecondary};
  }
`;
export const LogoutContainer = styled.div`
  align-items: center;
  color: red;
  width: 100%;
  padding: 12px 0 0 3vw;

  display: flex;

  div {
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  h1 {
    margin: 0 0 0 12px;
    color: red;
    font-size: 16px;
  }
  @media ${device.laptop} {
    cursor: pointer;
    padding: 1vw 0 0 3vw;

    h1 {
      font-size: ${desktop.small};
      margin: 0 0 0 2vw;
    }
    div {
      font-size: ${desktop.medium};
    }
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  img {
    height: 45px;
    margin-right: 10px;
  }
  h1 {
    margin: 0;
    font-weight: 600;
    font-family: montserrat;
    color: ${(props) => props.theme.primaryColor};
  }
  @media ${device.laptop} {
    img {
      height: 3vw;
      margin-right: 10px;
    }
  }
`;

export const Dpcontainer = styled.div`
  margin: 0 auto;
  text-align: center;
  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: ${(props) => props.theme.authShadow};
  }
  & > p {
    margin: 10px 0 0 0;
    font-family: montserrat;
    text-align: center;
    text-transform: capitalize;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.medium};
    font-weight: 600;
  }
  .points {
    margin: 0 0 0 0;
    font-family: montserrat;
    text-align: center;
    color: ${(props) => props.theme.mutedColorTertiary};
    font-size: 14px;
    font-weight: 500;
  }
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;

export const FollowerTag = styled.div`
  border-right: ${(props) => ` 1px solid ${props.theme.mutedColorPrimary}`};
  padding: 10px;
  display: flex;
  align-items: center;
  & > span {
    color: ${(props) => props.theme.mutedColorSecondary};
    font-size: ${desktop.medium};
    margin-right: 10px;
  }
  :last-child {
    border: none;
  }
  & > div {
    display: block;
    & > p {
      margin: 0;
      font-weight: 600;
      text-align: start;
      font-family: Montserrat;
      color: ${(props) => props.theme.textColor};
      font-size: 12px;
      :last-child {
        margin: 0;
        font-weight: 500;
        color: ${(props) => props.theme.mutedColorSecondary};
        font-size: 12px;
        font-family: Sora;
      }
    }
  }
  @media ${device.laptop} {
    /* margin-top: 10px; */
    & > div {
      display: block;
      & > p {
        margin: 0;
        font-weight: 600;
        text-align: start;
        font-family: Montserrat;
        color: ${(props) => props.theme.textColor};
        font-size: ${desktop.small};
        :last-child {
          margin: 0;
          font-weight: 500;
          color: ${(props) => props.theme.mutedColorSecondary};
          font-size: ${desktop.xSmall};
          font-family: Sora;
        }
      }
    }
  }
`;
