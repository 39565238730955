import React, { useEffect, useState } from "react";
import Auth, { Gradient } from "../../components/layouts/Auth";
import vermoLogo from "../../asset/vermoLogo.png";
import bg from "../../asset/bg.jpg";

import {
  Container,
  LogoContainer,
  PlanContainer,
  StyledCol,
  StyledCollapse,
  StyledPanel,
  StyledRow,
} from "./Style";
import {
  GeneralDataSelector,
  getUserAction,
  mode,
  // paySubscriptionAction,
  toggleDark,
  toggleLight,
  useAppDispatch,
  useAppSelector,
} from "@chidiahua/vermo-redux";
import {
  CaretRightOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ButtonComponent } from "../../components/common/Button";
import { PlanProps } from "../../constants/types";
import { Radio } from "antd";
import { is } from "immer/dist/internal";
import { NotificationAlert } from "../../utils/NotificationAlert";

const plan: PlanProps[] = [
  {
    amount: 4,
    shortText:
      "Everything included in the starter plan which permutations that have gone through our proprietary software that guarantees at least 50% ...",
    type: "Starter",
  },
  {
    amount: 8,
    shortText:
      "Everything in the standard plan with weekly private predictions that have gone through our proprietary software, with returns of 70% ...",
    type: "Standard",
  },
  {
    amount: 15,
    shortText:
      "Everything in the standard plan with weekly private predictions that have gone through our proprietary software, with returns of 85% ...",
    type: "Pro",
  },
];
const Index = () => {
  const lightmode = useAppSelector(mode);
  const dispatcher = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const onChange = (): void => {
    lightmode === "dark" ? dispatcher(toggleLight()) : dispatcher(toggleDark());
  };
  const generalState = GeneralDataSelector() as any;
  const userDetails = generalState?.userDetails?.me;
  useEffect(() => {
    dispatcher(getUserAction({}));
  }, []);
  const handlePlan = () => {
    // setLoading(true);
    // dispatcher(paySubscriptionAction({ amount: amount }))
    //   .unwrap()
    //   .then((originalPromiseResponse) => {
    //     setLoading(false);
    //     // console.log("paySubscriptionAction", originalPromiseResponse);
    //     if (!originalPromiseResponse?.payForSubcription?.status) {
    //       NotificationAlert(
    //         "error",
    //         "Error occured",
    //         originalPromiseResponse?.fundWallet?.msg
    //       );
    //     } else {
    //       NotificationAlert(
    //         "success",
    //         "Successful",
    //         originalPromiseResponse?.payForSubcription?.msg
    //       );
    //       window.location.href =
    //         originalPromiseResponse?.payForSubcription?.paymentUrl;
    //     }
    //     localStorage.setItem(
    //       "sub",
    //       originalPromiseResponse?.payForSubcription?.paymentUrl
    //     );
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     NotificationAlert("error", "Error occured", err.message);
    //   });
  };
  const handleChangePlan = (planAmount: number) => {
    if (planAmount === amount) {
      setAmount(0);
    } else {
      setAmount(planAmount);
    }
  };
  const planView = plan.map((data, idx) => {
    return (
      <PlanContainer
        onClick={() => handleChangePlan(data.amount)}
        active={amount === data.amount ? true : false}
        key={idx}
      >
        <Radio checked={amount === data.amount ? true : false} className="" />
        <div style={{ marginLeft: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="header">{data?.type}</p>
            <p className="header">${data.amount} /mo.</p>
          </div>
          <p className="description">{data?.shortText}</p>
        </div>
      </PlanContainer>
    );
  });
  return (
    <div
      style={{
        overflowY: "auto",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 10000,
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100vw",
          height: "100%",
        }}
      >
        <img
          alt="background"
          src={bg}
          style={{
            filter: "grayscale(80%)",
            width: "100vw",
            height: "100vh",
          }}
        />
      </div>
      <Gradient />
      <Container>
        <div
          onClick={onChange}
          style={{
            zIndex: 1000,
            top: "20px",
            right: "20px",
            position: "fixed",
            cursor: "pointer",
          }}
        >
          {lightmode === "light" ? "🌞" : "🌜"}
        </div>
        <LogoContainer>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={vermoLogo} alt="logo" />
            <h1>Vermo</h1>
          </div>
        </LogoContainer>
        <StyledRow justify="space-between">
          <StyledCol lg={{ span: 12 }} sm={{ span: 24 }}>
            <h1 className="title">Welcome to Vermo, {userDetails?.name}</h1>
            <h2 className="subtitle">Select your Vermo plan</h2>
            <StyledCollapse
              className="site-collapse-custom-collapse"
              bordered={false}
              defaultActiveKey={"1"}
              accordion
              expandIconPosition="right"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusOutlined />
                ) : (
                  <PlusOutlined rotate={isActive ? 90 : 0} />
                )
              }
              //   destroyInactivePanel={true}
            >
              <StyledPanel header={`Should I the choose Starter Plan?`} key={1}>
                <p className="accordionText">
                  Everything included in the starter plan which permutations
                  that have gone through our proprietary software that
                  guarantees at least 50% returns on winning. the starter plan
                  is a simple plan that provides you with varieties of social
                  activities and predictions for the listed leagues.
                  <ul style={{ margin: 0 }}>
                    <li>English premier league Division II</li>
                    <li>La Liga division II</li>
                    <li>Serie A</li>
                    <li>Bundesliga</li>
                  </ul>
                </p>
              </StyledPanel>
              <StyledPanel header={`Why choose the Standard Plan?`} key="2">
                <p className="accordionText">
                  Everything in the standard plan with weekly private
                  predictions that have gone through our proprietary software,
                  with returns of 70% guaranteed. The standard plan provides you
                  with predictions on a wider range of leagues listed below
                  including API stats.
                  <ul style={{ margin: 0 }}>
                    <li>English premier league Division II</li>
                    <li>La Liga division II</li>
                    <li>Serie A</li>
                    <li>Bundesliga</li>
                    <li>API Statistics included</li>
                  </ul>
                </p>
              </StyledPanel>
              <StyledPanel header={`Is the Pro Plan the best?`} key="3">
                <p className="accordionText">
                  Everything in the standard plan with weekly private
                  predictions that have gone through our proprietary software,
                  with returns of 85% guaranteed. The standard plan provides you
                  with predictions on a All leagues including API stats and the
                  Major Red Rhino Prediction table games that comes with Amazing
                  Prizes placed on each Prediction table:
                  <ul style={{ margin: 0 }}>
                    <li>API statistics(All leagues)</li>
                    <li>Red Rhino prediction Table games</li>
                    <li>
                      Win amazing prizes by participating in the Red Rhinos
                      Table
                    </li>
                  </ul>
                </p>
              </StyledPanel>
            </StyledCollapse>
          </StyledCol>
          <StyledCol lg={{ span: 10 }} sm={{ span: 24, order: 1 }}>
            {planView}
            <br />
            <ButtonComponent
              text="Continue"
              onClick={handlePlan}
              loading={loading}
              disabled={amount === 0 ? true : false}
            />
            <br />
          </StyledCol>
        </StyledRow>
      </Container>
    </div>
  );
};

export default Index;
