import { UserOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import React from "react";
import { formatNumberAmount } from "../../utils/helperfuntions";
import { ConfirmBetContent } from "../../Views/GameDetails/Style";
import { ModalComponent } from "../common/Modal";
import { NotificationText } from "../common/StyleComponents";
import { useNavigate } from "react-router-dom";
interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
  details: any;
}
const BetHistoryModal: React.FC<ModalInterface> = ({
  onCancel,
  visible,
  details,
}) => {
  const navigate = useNavigate();
  
  return (

    <ModalComponent visible={visible} onCancel={onCancel}>
      <div style={{ padding: "1vw", width: "100%" }}>
        <NotificationText>Bet History Details</NotificationText>
        <br />
        <ConfirmBetContent>
          <p className="details">
            The details below shows the bet history details
          </p>
          <p className="detailsInfo">
            Bet amount: <span>$ {formatNumberAmount(details?.amount)}</span>
          </p>
          <p className="detailsInfo">
            Total Pot: <span>$ {formatNumberAmount(details?.amount * 2)}</span>
          </p>

          <p className="detailsInfo">
            Date and Time :{" "}
            <span>
              {moment(details?.createdAt).format("MMM D, YYYY")}.{"  "}
              {moment(details?.createdAt).format("HH:MM A")}
            </span>
          </p>

          <br />
          {details?.gameBet !== null && (
            <>
            
              <p className="details">Challenge Details</p>
              <p className="detailsInfo">
                Game Type : <span>{details?.gameType}</span>
              </p>
              <p className="detailsInfo">
                Bet Type : <span>{details?.betType}</span>
              </p>
              <p className="detailsInfo">
                Handicap : <span>{details?.optionsCreatedBy}</span>
              </p>
              <p className="detailsInfo">
                Created for team : <span>{details?.createdByTeam}</span>
              </p>
              <p className="detailsInfo">
                Accepted for team : <span>{details?.acceptedByTeam}</span>
              </p>
              <p className="detailsInfo">
                Bet won: <span>{details?.winner?.name}</span>
              </p>
              <p className="detailsInfo">
              <div
                onClick={() =>
                  navigate("/details", {
                    state: {
                      showChat: false,
                      showOdds: false,
                      match: details,
                      data: {},
                    },
                  })
                }
                className="maxWrapper"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              > Match Statistics &gt;&gt;&gt;
              </div>
              </p>
              <br />
              <Row style={{ width: "100%" }} justify="space-between">
                <Col xs={{ span: 24 }} lg={{ span: 11 }}>
                  <p className="details">Challenge Creator's Details</p>
                  <Avatar
                    style={{ flexShrink: 0, marginRight: "15px" }}
                    size="large"
                    src={details?.gameBet?.acceptedBy?.pic}
                    alt={`user `}
                    icon={<UserOutlined />}
                  />
                  <p className="detailsInfo">
                    Name : <span>{details?.createdBy?.name}</span>
                  </p>
                  <p className="detailsInfo">
                    Gender :{" "}
                    <span>
                      {details?.createdBy?.gender === "M" ? "Male" : "Female"}
                    </span>
                  </p>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 11 }}>
                  <p className="details"> Challenger's Details</p>
                  <Avatar
                    style={{ flexShrink: 0, marginRight: "15px" }}
                    size="large"
                    src={details?.acceptedBy?.pic}
                    alt={`user `}
                    icon={<UserOutlined />}
                  />
                  <p className="detailsInfo">
                    Name : <span>{details?.acceptedBy?.name}</span>
                  </p>
                  <p className="detailsInfo">
                    Gender :{" "}
                    <span>
                      {details?.acceptedBy?.gender === "M"
                        ? "Male"
                        : "Female"}
                    </span>
                  </p>
                </Col>
              </Row>
            </>
          )}
        </ConfirmBetContent>
      </div>
    </ModalComponent>
  );
};

export default BetHistoryModal;
