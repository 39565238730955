import {
  AcceptedPaymentMethodSelector,
  confirmPayout,
  getAcceptedPaymentMethod,
  initPayOut,
} from "@chidiahua/vermo-redux";
import { Alert, Button, } from "antd";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { NotificationAlert } from "../../../utils/NotificationAlert";
import { ButtonComponent } from "../../common/Button";
import { NotificationText } from "../../common/StyleComponents";
import { ModalText } from "../Style";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Vermo } from "../../common/styled";


interface PayloadProps {
  amount: number;
  acceptedPayoutMethodId: string;
  address: string;
  otp?: string
}


const ConfirmScreen = ({ payload, close } : { payload: PayloadProps, close: () => void}) => {
  const dispatcher: ThunkDispatch<any, any, any> = useDispatch()
  const [loading, setLoading] = React.useState(false);
  const [resetLoading, setResetLoading] = React.useState(false);
  const { getAcceptedPaymentMethods} =  AcceptedPaymentMethodSelector() as any

  useEffect(() => {
    dispatcher(getAcceptedPaymentMethod({}))
  },[dispatcher])



  const [time, setTimer] = React.useState(90);
  const tick = () => {
    setTimer(time - 1);
  };
  React.useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });

  const ResendToken = () => {
    setTimer(90);
    setResetLoading(true);
    dispatcher(
      initPayOut({
        amount: Math.floor(payload?.amount),
        userPayoutMethodId: payload?.acceptedPayoutMethodId,
      })
    )
      .unwrap()
      .then((res: any) => {
        setResetLoading(false);
        if (res?.initPayOut?.status) {
          NotificationAlert(
            "success",
            "OTP Sent, Check mail for Verification Token"
          );
        } else {
          NotificationAlert(
            "error",
            res?.initPayOut?.msg
          );
        }
      })
      .catch((err) => {
        setResetLoading(false);
        NotificationAlert("error", "Error occured", "Please try again.");
      });
  };
 

  const handleConfirmPayout = async (values: any) => {
    payload = {
      amount: payload.amount,
      address: payload.address,
      acceptedPayoutMethodId: payload.acceptedPayoutMethodId,
      otp: values?.otp
    }
    setLoading(true);
    dispatcher(confirmPayout(payload)).unwrap()
      .then((res: any) => {
        setLoading(false);
        if (res?.confirmPayout?.status) {
          NotificationAlert(
            "success",
            res?.confirmPayout?.msg
          );
          close()
        } else {
          NotificationAlert(
            "error",
            "Error occured",
            res?.confirmPayout?.msg
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error occured", "Please try again.");
      });
  };

  const formik = useFormik({
    initialValues: {
        amount: payload.amount,
        address: payload.address,
        acceptedPayoutMethodId: payload.acceptedPayoutMethodId,
        otp: ""
    },
    onSubmit: handleConfirmPayout
  })
  return (
    <div style={{ textAlign: "start", padding: "20px 20px 0px 20px" }}>
      <ModalText>Confirm Withdrawal</ModalText>

      <br />

      <NotificationText style={{ textAlign: "start" }}>
        A Verification code has been sent to your email. Kindly input in
        textfield below to confirm withdrawal.
      </NotificationText>
      <br />
      <form onSubmit={formik.handleSubmit}>

             <Vermo.TextTitle>Amount</Vermo.TextTitle>
             <Vermo.InputField name="amount" value={payload?.amount} disabled  style={{marginBottom: "15px"}}/>

            <Vermo.TextTitle>Payment Method</Vermo.TextTitle>
            <Vermo.SelectField value={payload?.acceptedPayoutMethodId} disabled>
              {getAcceptedPaymentMethods?.map((item: any) => (
                <Vermo.SelectOption key={item?.id} value={item?.id}>
                  {item?.name}
                </Vermo.SelectOption>
              ))}
            </Vermo.SelectField>

            <Vermo.TextTitle>Payout Address</Vermo.TextTitle>
            <Vermo.InputField name="address" value={payload?.address} disabled   style={{marginBottom: "15px"}}/>

            <Vermo.TextTitle>Verification Code</Vermo.TextTitle>
             <Vermo.InputField name="otp" placeholder="Enter Verification Code" value={formik.values.otp} onChange={formik.handleChange}  style={{marginBottom: "15px"}} />

            <Alert
              showIcon
              message="Didn't receive token?"
              type="info"
              action={
                <Button
                  onClick={ResendToken}
                  loading={resetLoading}
                  disabled={time > 0}
                  size="small"
                  danger
                >
                  {time > 0 && time} Resend Token
                </Button>
              }
              style={{marginBottom: "20px"}}
            />

              <div style={{ display: "flex", width:  "100%", gap:  "4px"}}>
              <ButtonComponent
                  loading={loading}
                  text={"Accept"}
                  style={{ marginTop: "10px" }}
                  htmlType="submit"
                />
                  <ButtonComponent
                  onClick={close}
                  text={"Cancel"}
                  style={{ marginTop: "10px" }}
                  htmlType="button"
                />
              </div>
      </form>
      <br />
    </div>
  );
};

export default ConfirmScreen;
