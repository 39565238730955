import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ModalText } from "../Style";
import { ButtonComponent } from "../../common/Button";
import { Col, Row } from "antd";
import {
  AcceptedPaymentMethodSelector,
  getAcceptedPaymentMethod,
  initPayOut,
} from "@chidiahua/vermo-redux";
import { Vermo } from "../../common/styled";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { NotificationAlert } from "../../../utils/NotificationAlert";
import { useNavigate } from "react-router-dom";
import ConfirmScreen from "./ConfirmScreen";
import { ModalComponent } from "../../common/ModalComponent";
interface IAmount {
  close: () => void;
}

interface PayloadProps {
  amount: number;
  acceptedPayoutMethodId: string;
  address: string;
}

const Amount: React.FC<IAmount> = ({ close  }) => {
  const dispatcher: ThunkDispatch<any, any, any> = useDispatch()
  const { getAcceptedPaymentMethods } =  AcceptedPaymentMethodSelector() as any
  const [ loading, setLoading ] = useState(false)
  const [payload, setPayload] = useState<PayloadProps | null>(null);
  const [confirmVisible, setConfirmVisible] = useState(false);



  useEffect(() => {
    dispatcher(getAcceptedPaymentMethod({}))
  },[dispatcher])

  const handleInitPayout = async (values: { amount: number, address: string, acceptedPayoutMethodId: string}) => {
    const payload = {
        amount: Number(values.amount),
        acceptedPayoutMethodId: values.acceptedPayoutMethodId,
        address: values.address,
    }
    setLoading(true)
   dispatcher(initPayOut(payload)).unwrap()
   .then((res: any) => {
      setLoading(false)
      if(res?.initPayout?.status === false) {
        NotificationAlert(
          'error',
          res?.initPayout?.msg
        )
      } else if(res?.initPayout?.status === true) {
        NotificationAlert(
          'success',
          res?.initPayout?.msg
        );
        setPayload(payload)
        setConfirmVisible(true)
      }
   })
  };

  const formik = useFormik({
    initialValues: {
      amount: 0,
      acceptedPayoutMethodId: "",
      address: ""
    },
    onSubmit: handleInitPayout
  })

  return (
    <div style={{ textAlign: "start" }}>
      <ModalText>Withdraw Funds</ModalText>
        <form onSubmit={formik.handleSubmit}>

        <>
        <Vermo.TextTitle>Amount</Vermo.TextTitle>
            <Vermo.InputField 
              style={{ marginBottom: "15px" }}
              name="amount"
              type="text"
              placeholder="Enter Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <Vermo.TextTitle>Payment Method</Vermo.TextTitle>
            <Vermo.SelectField
              bordered={false}
              size="large"
              value={formik.values.acceptedPayoutMethodId || undefined}
              onChange={(value) => formik.setFieldValue("acceptedPayoutMethodId", value)}
              placeholder="Select Accepted Payout Method"
            >
                {getAcceptedPaymentMethods?.map((item: any) => (
                  <Vermo.SelectOption key={item?.id} value={item?.id}>
                      {item?.name}
                  </Vermo.SelectOption>
                ))}
            </Vermo.SelectField>

            <Vermo.TextTitle>Address</Vermo.TextTitle>
            <Vermo.InputField 
             style={{ marginBottom: "15px" }}
              name="address"
              type="text"
              placeholder="Enter Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <Row style={{ width: "100%" }} justify="space-between">
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  colorprop={true}
                  onClick={close}
                  text={"Close"}
                  style={{ marginTop: "10px" }}
                />
              </Col>
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  htmlType="submit"
                  loading={loading}
                  text={"Continue"}
                  style={{ marginTop: "10px" }}
                />
              </Col>
            </Row>
          </>
        </form>
      <br />

      <ModalComponent visible={confirmVisible}>
        {payload && <ConfirmScreen payload={payload} close={() => setConfirmVisible(false)} />}
      </ModalComponent>
    </div>
  );
};

export default Amount;
