import React, { useEffect } from "react";
import { ButtonComponent } from "../common/Button";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import {
  ChallengesSelector,
  getSingleChallengeDetailsAction,
  useAppDispatch,
  acceptChallengeAction,
  getAllChallengeAction,
  UserWalletSelector,
  getMyLockedChallengeAction,
} from "@chidiahua/vermo-redux";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { NotificationAlert } from "../../utils/NotificationAlert";
import Avatar from "antd/lib/avatar/avatar";
import { ConfirmBetContent } from "../../Views/GameDetails/Style";
import { formatNumberAmount } from "../../utils/helperfuntions";
import { Container, Dropdown, Option } from "../common/DropDown";
interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
  data?: any;
}
const AcceptChallengeModal: React.FC<ModalInterface> = ({
  onCancel,
  visible,
  data,
}) => {
  const wallets = UserWalletSelector() as any;

  const { getSingleChallengeLoading, getSingleChallengeDetails } = ChallengesSelector() as any;

  const dispatcher = useAppDispatch() as any;
  const [loadingState, setLoading] = React.useState(false);
  const [walletValue, setWalletValue] = React.useState() as any;


  useEffect(() => {
    dispatcher(getSingleChallengeDetailsAction({ gameBetId: data?.id }));
  }, [dispatcher]);

  const handleSubmit = () => {
    const payload = {
      gameBetId: data?.id,
      walletId: walletValue,
    };
    setLoading(true);
    dispatcher(acceptChallengeAction({ ...payload }))
      .unwrap()
      .then((originalPromiseResponse: any) => {
        setLoading(false);
        if (!originalPromiseResponse?.acceptChallenge?.status) {
          onCancel();

          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.acceptChallenge?.msg
          );
        } else {
          dispatcher(getMyLockedChallengeAction({}));
          onCancel();
          dispatcher(getAllChallengeAction({}));
          NotificationAlert(
            "success",
            "Challenge successfully locked.",
            originalPromiseResponse?.acceptChallenge?.msg
          );
          window.location.reload()
        }
      })
      .catch((err: any) => {
        setLoading(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };

  return (
    <>
      <ModalComponent visible={visible} onCancel={onCancel}>
        <div style={{ padding: "1vw", width: "100%", textAlign: "center" }}>
          {getSingleChallengeLoading ? (
            <div
              style={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <LoadingOutlined style={{ color: "#603be8" }} />
                <NotificationText>
                  Loading challenge details, please wait...
                </NotificationText>
              </div>
            </div>
          ) : (
            <div>
              <NotificationText>Challenge Details</NotificationText>
              <br />
              <ConfirmBetContent>
                <p className="details">
                  You are about to lock a bet challenge, a sum of
                  <span style={{ color: "green" }}>
                    {" "}
                    $
                    {formatNumberAmount(
                      +getSingleChallengeDetails?.singleChallenge?.amount
                    ) ?? "0"}
                  </span>
                  will be deducted from your wallet.
                </p>
                <br />

                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={
                    getSingleChallengeDetails?.singleChallenge
                      ?.createdUser?.image
                  }
                  style={{
                    flexShrink: 0,
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                  icon={<UserOutlined />}
                />
                <br />
                <br />
                <p className="detailsInfo">
                  Challenger's Name:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallenge
                        ?.createdBy?.name
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  Game Type:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallenge
                        ?.gameType
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  Bet Type:{" "}
                  <span>
                    {getSingleChallengeDetails?.singleChallenge?.betType}
                  </span>
                </p>
                <p className="detailsInfo">
                  Challenger's Selection:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallenge
                        ?.createdByTeam
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  My Accepted Selection:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallenge
                        ?.acceptedByTeam
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  Stake Amount:{" "}
                  <span>
                  $
                    {formatNumberAmount(
                      +getSingleChallengeDetails?.singleChallenge?.amount
                    )}
                  </span>
                </p>
                <p className="detailsInfo">
                  Winning:{" "}
                  <span>
                  $
                    {formatNumberAmount(
                      +getSingleChallengeDetails?.singleChallenge
                        ?.amount * 2
                    )}
                  </span>
                </p>
                <p className="detailsInfo">Select Withdrawal Wallet: </p>
                <Container>
                  <Dropdown onChange={(e) => setWalletValue(e?.target?.value)}>
                    <Option disabled selected value="">
                      Select a wallet
                    </Option>
                    {wallets?.getUserWallets?.map((wallet: any) => (
                      <Option value={wallet?.id
                      }>
                        {wallet?.currencyType?.kind}
                      </Option>
                    ))}
                  </Dropdown>
                </Container>
              </ConfirmBetContent>
              <br />
                    <div style={{ display: "flex", gap: "5px",  width: "100%"}}>
                    <ButtonComponent
                disabled={!walletValue}
                loading={loadingState}
                onClick={handleSubmit}
                text={"Accept Challenge"}
                style={{ margin: "10px 0", width: "100%" }}
              />
               <ButtonComponent
                onClick={onCancel}
                text={"Cancel"}
                style={{ margin: "10px 0", width: "100%" }}
              />
                    </div>
              <br />
            </div>
          )}
        </div>
      </ModalComponent>
    </>
  );
};

export default AcceptChallengeModal;
