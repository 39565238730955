import React from "react";
import {
  ChallengesSelector,
  useAppDispatch,
  getChallengeHistoryAction,
  GeneralDataSelector,
} from "@chidiahua/vermo-redux";
import moment from "moment";
import { PageTitle, Selection, Team } from "../common/StyleComponents";
import {
  formatNumberAmount,
  mapChallengeKey,
} from "../../utils/helperfuntions";
import { StyledTable } from "../common/TableComponent";
import { Desc } from "./LockedChallenges";
import BetHistoryModal from "../Modals/BetHistoryModal";

const ChallengeHistory = () => {
  const dispatcher = useAppDispatch() as any;
  const { getChallengeHistoryLoading, challengeHistory } = ChallengesSelector() as any;
  const { userDetails } = GeneralDataSelector() as any;
  const [showModal, setShowModal] = React.useState(false);
  const [currentData, setCurrentData] = React.useState({}) as any;


  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, others: any) => (
        <div>
          <div style={{ display: "flex" }}>
            {moment(text).format("DD.MM.YYYY")}
            {/* {others?.live && <Live>LIVE</Live>} */}
          </div>
          <div>{moment(text).format("hh:mm a")}</div>
        </div>
      ),
    },
    {
      title: "Game Type",
      dataIndex: "gameType",
      key: "gameType",
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (props: any, all: any) => (
        <Team>
          <div className="content">
            {/* <img className="image" src={props?.homeLogo} alt={props.homeName} /> */}
            <p className="text" style={{ textAlign: "center" }}>
              {all.createdByTeam}
            </p>
          </div>
          <p className="text">VS</p>
          <div className="content">
            {/* <img className="image" src={props?.awayLogo} alt={props.awayName} /> */}
            <p className="text">{all.acceptedByTeam}</p>
          </div>
        </Team>
      ),
    },
    {
      title: "Bet Type",
      dataIndex: "betType",
      key: "betType",
      render: (text: string) => <div>{mapChallengeKey(text)}</div>,
    },

    {
      title: "Selections",
      dataIndex: "selection",
      key: "selection",
      render: (text: string, other: any) => {
        console.log("others", other);
        return (
          <div>
            <Selection
              style={{
                border: `1px solid ${
                  other?.createdBy?.id ? "green" : "red"
                }`,
              }}
            >
              {other?.createdByTeam}
            </Selection>
            <Selection
              style={{
                border: `1px solid ${
                  other?.acceptedBy?.id ? "red" : "green"
                }`,
              }}
            >
              {other?.acceptedByTeam}
            </Selection>
          </div>
        );
      },
    },

    {
      title: "Winning",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div>
          <Team>${formatNumberAmount(+text * 2)}</Team>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "winnerId",
      key: "winnerId",
      render: (winnerId: string, text: any) => `${ winnerId == "0" ? "Refund" : winnerId === userDetails?.me?.id ? "Won" : "Loss"} `
    },
  ];

  React.useEffect(() => {
    dispatcher(getChallengeHistoryAction({}));
  }, []);
  return (
    <div>
      <PageTitle>
        <h2>Selection description</h2>
      </PageTitle>
      <Desc>
        <div>
          <div className="mySelection"></div>
          <p>My selection</p>
          <div className="challengerSelection"></div>
          <p>Opponent's selection</p>
        </div>
      </Desc>
      {showModal && (
        <BetHistoryModal
          details={currentData}
          onCancel={() => {
            setShowModal(!showModal);
          }}
          visible={showModal}
        />
      )}
      <StyledTable
        scroll={{ x: 600 }}
        columns={columns}
        dataSource={challengeHistory?.challengeHistory}
        loading={getChallengeHistoryLoading}
        onRow={(record: any) => {
          return {
            onClick: (e) => {
              setShowModal(!showModal);
              setCurrentData(record);
            },
          };
        }}
      />
    </div>
  );
};

export default ChallengeHistory;
