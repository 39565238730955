import React from "react";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop } from "../../theme/fontSize";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  useAppDispatch,
  useAppSelector,
  toggleDark,
  toggleLight,
  mode,
  GeneralDataSelector,
} from "@chidiahua/vermo-redux";
import { Skeleton } from "antd";

// import { mode, toggleDark, toggleLight } from "../common/slice/modeSlice";

const TopBar = () => {
  const lightmode = useAppSelector(mode);
  const dispatcher = useAppDispatch() as any;
  const onChange = (): void => {
    lightmode === "dark" ? dispatcher(toggleLight()) : dispatcher(toggleDark());
  };
  const { userDetails, loading } = GeneralDataSelector() as any;
  const userDetail = userDetails?.me;

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <p className="welcomeNote">
          Hello <span>{loading ? <Skeleton.Input active={true} size="small" /> : userDetail?.name}</span>, welcome back!
        </p>
        <div>
          <div onClick={onChange} className="others">
            {lightmode === "light" ? "🌞" : "🌜"}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TopBar;
const Container = styled.div`
  display: none;
  @media ${device.laptop} {
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* display: block; */
    background: ${(props) => props.theme.background};
    /* box-shadow:${(props) => props.theme.subtleShadow}; */
    position: sticky;
    border-bottom: ${(props) => `1px solid ${props.theme.mutedColorPrimary}`};
    top: 0;
    z-index: 4;
    width: 100%;
    padding: 1.5vh 2vw;
  }
  .welcomeNote {
    font-size: ${desktop.normal};
    font-weight: 400;
    color: ${(props) => props.theme.mutedColorTertiary};
    margin: 0;
    span {
      text-transform: capitalize;
    }
  }
  .others {
    /* padding: 10px; */
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.foreground};
    box-shadow: ${(props) => props.theme.authShadow};
    margin-right: 15px;
    font-size: ${desktop.medium};
  }
`;
